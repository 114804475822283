import './App.css';
import AppRoutes from './Routes/AppRoutes';

function App() {
  return (
    <div className="App">
           <AppRoutes />
    </div>
  );
}

export default App;
